import { NavLink, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { useState } from 'react'
import { FaBars, FaTimes } from 'react-icons/fa'

const Menu = () => {
  const location = useLocation()

  const menuItems = [
    {
      title: 'Home',
      url: '/',
    },
    {
      title: 'Bookshelf',
      url: '/bookshelf',
    },
    {
      title: 'Contact',
      url: '/contact',
    },
  ]

  const [showMenu, setShowMenu] = useState(false)

  const handleLinkClick = () => {
    setShowMenu(false)
  }

  return (
    <nav>
      <HamburgerIcon onClick={() => setShowMenu(!showMenu)}>
      {showMenu ? <FaTimes /> : <FaBars />}
      </HamburgerIcon>
      
      <List showMenu={showMenu}>
        {menuItems.map((menuItem) => (
          <ListItem
            selected={menuItem.url === location.pathname}
            key={menuItem.url}
          >
            <NavLink className={ListItem} to={menuItem.url} onClick={handleLinkClick}>
              {menuItem.title}
            </NavLink>
          </ListItem>
        ))}
        <CloseButton onClick={() => setShowMenu(false)} showMenu={showMenu}>Close</CloseButton>
      </List>
      
    </nav>
  )
}

const CloseButton = styled.button<{ showMenu: boolean }>`
  display: none;
  
  @media only screen and (max-width: 768px) {
    display: ${(props) => (props.showMenu ? 'block' : 'none')};
    cursor: pointer;
    background-color: transparent;
    border: none;
    color: ${(props) => props.theme.palette.primary};
    font-size: 1rem;
    font-weight: bold;
    margin-top: 10px;
    text-transform: uppercase;
  }
`

const List = styled.ul<{ showMenu: boolean }>`
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;

  @media only screen and (max-width: 768px) {
    display: ${(props) => (props.showMenu ? 'flex' : 'none')};
    flex-direction: column;
    position: absolute;
    top: 70px;
    left: 0;
    width: 100%;
    background-color: white;
    z-index: 1;
    padding: 10px 0;
    /* Add the CloseButton component here */
    & > ${CloseButton} {
      display: block;
    }
  }
`

const ListItem = styled.li<{
  selected: boolean
}>`
  margin: 0;
  padding: 0;

  a {
    background-color: ${(props) =>
      props.selected ? props.theme.palette.primary : 'transparent'};
    display: block;
    color: ${(props) =>
      props.selected ? 'white' : 'black'};
    letter-spacing: 2px;
    font-family: Primary, sans-serif;
    font-size: 1rem;
    font-weight: ${(props) =>
      props.selected ? 'bold' : 'normal'};
    padding: 20px;
    text-transform: uppercase;
  }
`
const HamburgerIcon = styled.div`
  display: none;

  @media only screen and (max-width: 768px) {
    display: block;
    cursor: pointer;
  }
`


export default Menu
