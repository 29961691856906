import * as React from 'react';

function SvgDown (props) {
  return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...props}>
            <g data-name="\uB808\uC774\uC5B4 2">
                <g data-name="\uB808\uC774\uC5B4 1">
                    <path fill="none" d="M0 0h48v48H0z" opacity={0.2} />
                    <path
                        d="M8.33 15.82l14.32 20.46a1.63 1.63 0 002.7 0l14.32-20.46A1.78 1.78 0 0038.32 13H9.68a1.78 1.78 0 00-1.35 2.82z"
                        stroke="#000"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                    />
                </g>
            </g>
        </svg>
  );
}

export default SvgDown;
