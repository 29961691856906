import React from 'react';
import styled, { css } from 'styled-components';
import palette from 'lib/styles/palette';
import ShadowHeadingLink from 'components/ShadowHeadingLink';
import NewsletterLink from 'components/NewsletterLink';

const Contact = () => {
  return (
    <>
      <Wrapper>
        <Layout>
          <SectionWrapper>
            <Image src="/images/articles/ReaderSupport.png" alt="Left Image" alignment="left" />
              <SectionContent>
              <ShadowHeadingLink
                title="Reader Support."
                description={`Have a question? We're here to help! If you're having an issue that isn't addressed elsewhere on the site, send us an email at support@readeum.io and we'll get back to you as soon as possible. Thanks for your patience!`}
              />
              </SectionContent>
          </SectionWrapper>
          <SectionWrapper>
            <SectionContent>
              <ShadowHeadingLink
                title="Author Inquiries."
                description={`Interested in publishing your book on Readeum? We'd love to hear from you! Please consider taking our author survey at https://forms.gle/NLqBEaynkbiRwJbb7 and don't hesitate to send us an email at authors@readeum.io to start a conversation about publishing your books to the blockchain. We look forward to hearing from you!`}
              />
              </SectionContent>
              <Image src="/images/articles/AuthorInquiries.png" alt="Right Image" alignment="right" />
          </SectionWrapper>
          <SectionWrapper>
            <Image src="/images/articles/MediaInquiries.png" alt="Left Image" alignment="left" />
              <SectionContent>
              <ShadowHeadingLink
                title="Media Inquiries"
                description={`For media inquiries, please send us an email at media@readeum.io with the details of your request, or just reach out to us on our social channels. Thanks for your interest in Readeum!`}
              />
            </SectionContent>
          </SectionWrapper>
        </Layout>
        <NewsletterLink />
      </Wrapper>
    </>
  );
};

const mobileBreakpoint = '768px';

const mobileOnly = css`
  @media (max-width: ${mobileBreakpoint}) {
    display: block;
  }
`;

const notMobile = css`
  @media (min-width: ${mobileBreakpoint}) {
    display: block;
  }
`;


const Wrapper = styled.div`
  width: 100%;
  height: auto;
  left: 0;
  top: 0;
  background-color: ${palette.white};
`;

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0 auto 100px auto;
  max-width: 1200px;
  padding: 0 10px;
`;

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;

  ${mobileOnly} {
    flex-direction: row;
    align-items: flex-start; /* Adjust alignment as needed */
    margin-bottom: 50px;
  }
`;

interface SectionContentProps {
  alignment?: 'left' | 'right';
}


const SectionContent = styled.div<SectionContentProps>`
  display: flex;
  flex-direction: column; /* Change to column */
  align-items: ${({ alignment }) => (alignment === 'right' ? 'flex-end' : 'flex-start')}; /* Adjust alignment as needed */
  width: 100%;
`;

interface ImageProps {
  src: string;
  alt: string;
  alignment?: 'left' | 'right';
}

const Image = styled.img<ImageProps>`
  width: 300px; /* adjust the width as needed */
  margin-right: ${({ alignment }) => (alignment === 'right' ? '0' : '20px')};
  margin-left: ${({ alignment }) => (alignment === 'right' ? '20px' : '0')};

`;


export default Contact;

