import styled from 'styled-components'
// lib
import * as styles from 'lib/styles/styles'
import zIndex from 'lib/styles/zIndex'

const ViewerWrapper = styled.div`
  @keyframes splash {
    from {
      /* opacity: 0; */
    }
    to {
      /* opacity: 1; */
    }
  }

  animation: splash 1s normal forwards ease-in-out;
  background-color: white;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100vw;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  z-index: ${zIndex.reader};
  ${styles.scrollbar(0)};
`

export default ViewerWrapper
