import { FC, ReactNode } from 'react'
import styled from 'styled-components'

export const Clickable: FC<{
  children: ReactNode
  onClick: () => any
}> = ({ children, onClick }) => <Button onClick={onClick}>{children}</Button>

const Button = styled.button`
  background: none;
  border: none;
  margin: 0;
  padding: 0;

  &:hover {
    background: none;
    border: none;
    margin: 0;
    padding: 0;
  }
`
