import styled from 'styled-components'

import Wallet from 'components/home/Wallet'
import Menu from 'components/Menu'

import Logo from './Logo'

const Header = () => {
  return (
    <Wrapper>
      <Container>
        <LogoContainer>
          <Logo />
        </LogoContainer>
        <Grow>
          <Menu />
        </Grow>
        <div>
          <Wallet />
        </div>
      </Container>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background-color: #fff;
  padding: 20px 10px;
  z-index: 999;
`

const Container = styled.div`
  align-items: center;
  display: flex;
  margin: 0 auto;
  max-width: 1200px;
`

const LogoContainer = styled.div`
  width: 200px;
`

const Grow = styled.div`
  flex-grow: 1;
`

export default Header
