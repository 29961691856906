import { FC } from 'react'
import styled from 'styled-components'

import { DiscordIcon } from './icons/DiscordIcon'
import { FacebookIcon } from './icons/FacebookIcon'
import { InstagramIcon } from './icons/InstagramIcon'
import { TwitterIcon } from './icons/TwitterIcon'
import { TelegramIcon } from './icons/TelegramIcon'

const socialItems = [
  {
    name: 'twitter',
    icon: TwitterIcon,
    url: 'https://twitter.com/Readeum_Project',
  },
  {
    name: 'discord',
    icon: DiscordIcon,
    url: 'https://discord.gg/FdWZfS5Wmj',
  },
  {
    name: 'telegram',
    icon: TelegramIcon,
    url: 'https://t.me/Readeum',
  },
  /*{
    name: 'facebook',
    icon: FacebookIcon,
    url: 'https://facebook.com',
  },
  {
    name: 'instagram',
    icon: InstagramIcon,
    url: 'https://instagram.com',
  },*/
]

const Social: FC<{
  color?: string
}> = ({ color = '#FFFFFF' }) => (
  <Wrapper>
    <List>
      {socialItems.map((socialItem) => (
        <ListItem key={socialItem.name}>
          <a href={socialItem.url} rel="noopener noreferrer" target="_blank">
            <socialItem.icon color={color} size={24} />
          </a>
        </ListItem>
      ))}
    </List>
  </Wrapper>
)

const Wrapper = styled.div`
  padding: 20px 10px;
  text-align: center;
`

const List = styled.ul`
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
`

const ListItem = styled.li`
  margin: 0 10px;
  padding: 0;

  &:first-of-type {
    margin: 0 10px 0 0;
  }

  &:last-of-type {
    margin: 0 0 0 10px;
  }
`

export default Social
