import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import Logo from './home/Logo'
import Social from './Social'

const Footer = () => {
  const navLinks = [
    {
      title: 'Home',
      url: '/',
    },
    {
      title: 'Bookshelf',
      url: '/bookshelf',
    },
    {
      title: 'Contact',
      url: '/contact',
    },
  ]

  const miscLinks = [
    {
      title: 'Terms of Service',
      url: '/terms',
    },
    {
      title: 'Privacy Policy',
      url: '/privacy',
    },
    {
      title: 'About Readeum',
      url: '/about',
    },
  ]

  return (
    <>
      <Top>
        <Wrapper>
          <Container>
            <LogoContainer>
              <Logo hideText size={64} />
            </LogoContainer>
            <Info>
              <Description>
                <p>
                  <a href="/">Readeum</a> is a platform that offers books as
                  non-fungible tokens (NFTs), giving book lovers a new and
                  innovative way to own and collect their favorite reads.
                </p>
              </Description>
            </Info>
            <Nav>
              <h4>Main Menu</h4>
              <List>
                {navLinks.map((link) => (
                  <ListItem key={link.url}>
                    <NavLink to={link.url}>{link.title}</NavLink>
                  </ListItem>
                ))}
              </List>
            </Nav>
            <Nav>
              <h4>Links</h4>
              <List>
                {miscLinks.map((link) => (
                  <ListItem key={link.url}>
                    <NavLink to={link.url}>{link.title}</NavLink>
                  </ListItem>
                ))}
              </List>
            </Nav>
          </Container>
        </Wrapper>
      </Top>
      <Bottom>
        <Wrapper>
          <Container>
            <Copyright>&copy; Readeum 2023</Copyright>
            <Social />
          </Container>
        </Wrapper>
      </Bottom>
    </>
  )
}

const Top = styled.div`
  background-color: #eee;
  padding: 40px 0;
`

const Bottom = styled.div`
  background-color: black;
  color: white;
`

const Wrapper = styled.footer`
  padding: 20px 0;
`

const Container = styled.div`
  align-items: center;
  display: flex;
  margin: 0 auto;
  max-width: 1200px;
`

const LogoContainer = styled.div`
  margin-right: 20px;
`

const Info = styled.div`
  line-height: 1.5rem;
  padding-right: 100px;
  width: 30%;

  a {
    border-bottom: 1px black solid;
    color: black;
    transition: border-bottom-color 0.5s, color 0.5s;

    &:hover {
      border-bottom-color: ${(props) => props.theme.palette.primary};
      color: ${(props) => props.theme.palette.primary};
    }
  }
`

const Description = styled.div`
  color: #999;
  font-size: 1rem;
`

const Nav = styled.nav`
  width: 30%;

  h4 {
    color: ${(prop) => prop.theme.palette.primary};
    font-family: Primary;
    text-transform: uppercase;
  }
`

const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`

const ListItem = styled.li`
  margin: 20px 0;
  padding: 0;

  a {
    color: black;
    transition: color 0.5s;

    &:hover {
      color: ${(props) => props.theme.palette.primary};
    }
  }
`

const Copyright = styled.div`
  flex-grow: 1;
`

export default Footer
