interface IBook {
  tokenId: number
  title: string
  author: string
  cost: number
  status: string,
  maxBooks: number,
  saleContract: `0x${string}`,
  saleIndex: number,
  projectId: string
}

const books: IBook[] = [
  {
    tokenId: 1,
    title: `Alice's Adventures in Wonderland`,
    author: 'Lewis Carroll',
    cost: 0.01,
    status: 'none',
    maxBooks: 0,
    saleContract: '0x00',
    saleIndex: 0,
    projectId: ''
  },
  {
    tokenId: 2,
    title: 'The Great Gatsby',
    author: 'Francis Scott Fitzgerald',
    cost: 0.01,
    status: 'none',
    maxBooks: 0,
    saleContract: '0x00',
    saleIndex: 0,
    projectId: ''
  },
  {
    tokenId: 3,
    title: 'The Complete Aristotle',
    author: 'Aristotle',
    cost: 0.01,
    status: 'none',
    maxBooks: 0,
    saleContract: '0x00',
    saleIndex: 0,
    projectId: ''
  },
  {
    tokenId: 4,
    title: 'The Art of Public Speaking',
    author: 'Dale Breckenridge Carnegie',
    cost: 0.01,
    status: 'none',
    maxBooks: 0,
    saleContract: '0x00',
    saleIndex: 0,
    projectId: ''
  },
  {
    tokenId: 5,
    title: 'The Secret Garden',
    author: 'Frances Hodgson Burnett',
    cost: 0.01,
    status: 'none',
    maxBooks: 0,
    saleContract: '0x00',
    saleIndex: 0,
    projectId: ''
  },
  {
    tokenId: 6,
    title: 'Hansel and Gretel',
    author: 'Brothers Grimm',
    cost: 0.01,
    status: 'none',
    maxBooks: 0,
    saleContract: '0x00',
    saleIndex: 0,
    projectId: ''
  },
  {
    tokenId: 7,
    title: 'Dracula',
    author: 'Bram Stoker',
    cost: 0.01,
    status: 'none',
    maxBooks: 0,
    saleContract: '0x00',
    saleIndex: 0,
    projectId: ''
  },
  {
    tokenId: 8,
    title: 'The Einstein Theory of Relativity',
    author: 'Hendrik Antoon Lorentz',
    cost: 0.01,
    status: 'none',
    maxBooks: 0,
    saleContract: '0x00',
    saleIndex: 0,
    projectId: ''
  },
  {
    tokenId: 9,
    title: `The Haunted House`,
    author: 'Charles Dickens',
    cost: 0.01,
    status: 'none',
    maxBooks: 0,
    saleContract: '0x00',
    saleIndex: 0,
    projectId: ''
  },
  {
    tokenId: 10,
    title: 'The Three Musketeers',
    author: 'Alexandre Dumas',
    cost: 0.01,
    status: 'none',
    maxBooks: 0,
    saleContract: '0x00',
    saleIndex: 0,
    projectId: ''
  },
  {
    tokenId: 11,
    title: 'Pride and Prejudice',
    author: 'Jane Austen',
    cost: 0.01,
    status: 'none',
    maxBooks: 0,
    saleContract: '0x00',
    saleIndex: 0,
    projectId: ''
  },
  {
    tokenId: 12,
    title: 'The Wonderful Wizard of Oz',
    author: 'L. Frank Baum',
    cost: 0.01,
    status: 'none',
    maxBooks: 0,
    saleContract: '0x00',
    saleIndex: 0,
    projectId: ''
  },
  {
    tokenId: 13,
    title: 'Grimms Fairy Tales',
    author: 'The Brothers Grimm',
    cost: 1,
    status: 'onSale',
    maxBooks: 10,
    saleContract: '0xe8d7478dB9FF1343ed265a7397E66444812bD77f',
    saleIndex: 0,
    projectId: ''
  },
  {
    tokenId: 14,
    title: 'Ruin',
    author: 'J.W. Walker',
    cost: 1,
    status: 'onSale',
    maxBooks: 10,
    saleContract: '0x7a89e81938ba818e5ce93007cbb7ee3c393ec7f0',
    saleIndex: 0,
    projectId: ''
  }
]

export default books
