import { useState } from 'react'
import styled, { keyframes } from 'styled-components'
import { LoadHash } from 'interact'
import { base64toArrayBuffer } from 'Base64toArrayBuffer'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { updateBookUrl } from 'app/reducers/bookReducer'
import Reader from 'Reader/containers/Reader'
import ControlBtn from 'components/home/ControlBtn'
import crypto from "crypto-browserify"
import { useNetwork } from 'wagmi'

const BookButtons = ({ contractAddress, walletAddress, url }: Props) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [bookUrl, setBookUrl] = useState<string | ArrayBuffer>('')
  const [encBookData, setEncBookData] = useState<ArrayBuffer>()
  const [loading, setLoading] = useState(false)
  const [isReady, setIsReady] = useState(false)
  const [bookDataLoaded, setBookDataLoaded] = useState(false)
  const {chain} = useNetwork();//retrieve chain
  const chainId = chain?.id
  //Update with book to ca for each new book mint for both polygon and eth addresses.  Must be lowercase characters to function properly.
  const bookData = [  { contractAddress: '0x7a89e81938ba818e5ce93007cbb7ee3c393ec7f0', bookName: 'Ruin' } ]
  
  const downloadBookData = async () => {
    setLoading(true)

    const res = await fetch(url.replace('gateway.pinata.cloud', 'cloudflare-ipfs.com'))
    const encBookData = await res.arrayBuffer()

    console.log(encBookData)
    console.log(loading)
    setEncBookData(encBookData)
    setLoading(false)
    setBookDataLoaded(true)

    if (encBookData != undefined && chainId != undefined) {
      const hashLoader = await LoadHash(walletAddress, contractAddress, chainId)
      console.log(hashLoader)
      //Lookup correct environment variables based on contract address
      const book = bookData.find(b => b.contractAddress === contractAddress)
    if (!book) {
      throw new Error(`No book found for contract address ${contractAddress}`)
    }
      console.log(book.bookName)
      // Extract key and IV from environment variables
      const aesKey = process.env[`REACT_APP_${book.bookName}_BOOK_KEY`];
      const aesIV = process.env[`REACT_APP_${book.bookName}_BOOK_IV`];
      console.log(aesKey)
      console.log(aesIV)
      // convert key and iv to binary
      if (aesKey != undefined && aesIV != undefined) {
      const binaryKey = Buffer.from(aesKey, 'utf8');
      const binaryIV = Buffer.from(aesIV, 'utf8');
      const key_iv = Buffer.concat([binaryKey, binaryIV])
      // Create a new SHA-256 hash object
      const hash = crypto.createHash('sha256');
      console.log(hash)
      // Update the hash object with the key and IV
      hash.update(key_iv);
      console.log(hash)
      // Get the hexadecimal representation of the hash
      const keyHash = hash.digest('hex');
      console.log(keyHash)
      // Compare the key hash to the keyHash in the smart contract
      if (keyHash === hashLoader){
        const bufferKey = base64toArrayBuffer(aesKey)
        const iv = base64toArrayBuffer(aesIV)
        const cryptoKey = await window.crypto.subtle.importKey(
          'raw',
          bufferKey,
          'AES-CBC',
          true,
          ['encrypt', 'decrypt']
      )
      const encoded = await window.crypto.subtle.decrypt({
        name: 'AES-CBC',
        iv
        }, cryptoKey, encBookData)
      //**Code to verify proper decryption by downloading as epub file */
      //const bookArray = new Uint8Array(encoded)
      //const downloadFile = new File([bookArray], 'myBook.epub')
      //FileSaver.saveAs(downloadFile)
      const bookUrl = encoded
      console.log(bookUrl)        
      setBookUrl(bookUrl)
      setIsReady(true)
    }
  }}}

  return (
    <>
      <ControlBtn onClick={downloadBookData} message={loading ? 'Loading...' : 'Read Book'} />
      {isReady && <Reader onClose={() => setIsReady(false)} url={bookUrl} />}
    </>
  )
}

interface Props {
  contractAddress: string
  walletAddress: string
  url: string
}

export default BookButtons
