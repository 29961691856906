import { FC } from 'react'

export const TwitterIcon: FC<{
  color?: string
  size?: number
}> = (props) => (
  <svg
    viewBox="0 0 152 152"
    xmlns="http://www.w3.org/2000/svg"
    width={props.size}
    height={props.size}

  >
    <g id="Layer_2" data-name="Layer 2">
      <g id="Color">
        <path
          id="_04.Twitter"
          fill={props.color}
          d="m76 0a76 76 0 1 0 76 76 76 76 0 0 0 -76-76zm39.12 56c0 .86.07 1.72.07 2.61 0 26.67-20.31 57.44-57.46 57.44a56.79 56.79 0 0 1 -31-9.06 36.45 36.45 0 0 0 4.83.28 40.59 40.59 0 0 0 25-8.61 20.39 20.39 0 0 1 -18.8-14.11 18 18 0 0 0 3.83.34 20.13 20.13 0 0 0 5.29-.66 20.28 20.28 0 0 1 -16.17-19.84v-.22a19.92 19.92 0 0 0 9.12 2.49 20.2 20.2 0 0 1 -6.21-27 57.16 57.16 0 0 0 41.61 21.13 20.87 20.87 0 0 1 -.47-4.6 20.18 20.18 0 0 1 34.92-13.8 40.32 40.32 0 0 0 12.8-4.89 20.06 20.06 0 0 1 -8.88 11.16 42 42 0 0 0 11.63-3.19 40.16 40.16 0 0 1 -10.11 10.53z"
          data-name="04.Twitter"
        />
      </g>
    </g>
  </svg>
)
