import React from 'react';
import styled from 'styled-components';
import palette from 'lib/styles/palette';
import ShadowHeadingLink from 'components/ShadowHeadingLink';
import NewsletterLink from 'components/NewsletterLink';

const About = () => {
  return (
    <>
      <Wrapper>
        <Layout>
          <SectionWrapper>
              <SectionContent>
              <ShadowHeadingLink
                title="About Readeum"
                description={`Welcome to Readeum, the revolutionary digital book publishing platform that is changing the game for authors and readers alike. Our platform is unlike any other, as we use blockchain technology to publish truly ownable digital books.
                `}
              />
              </SectionContent>
              <Image src="/images/articles/AboutReadeum.png" alt="Center Image" alignment="center" />
              <SectionContent>
              <ShadowHeadingLink 
                title=''
                description={`At Readeum, we understand the frustration that many authors and readers have with traditional digital publishing platforms. That's why we've created a platform that is secure, transparent, and truly innovative. With blockchain technology, we can ensure that every book published on our platform is unique, verifiable, and truly owned by the reader.`}/>
              <ShadowHeadingLink 
                title=''
                description={`Our founder, J.W. Walker, is a self-published author and avid reader who understands the challenges of the digital publishing industry. He knew that there had to be a better way, and that's why he founded Readeum. With our platform, authors can publish their work with confidence, knowing that their books are secure and truly owned by their readers.
                Readers can also enjoy a truly unique reading experience on Readeum. Our platform allows readers to truly own their digital books, with the ability to transfer, sell, or even donate their books to others. But we won't stop there. Immersive technologies and trustless digital lending technologies are evolving quickly and you can be certain that Readeum will not hesitate to adopt disruptive technology that brings new opportunity to authors, and exciting new experiences to readers.
                With Readeum, readers can build their own digital libraries, knowing that their books are secure and truly theirs.  You can finally do whatever you want with your digital content.  You paid for, it's yours!`}/>
              <ShadowHeadingLink 
                title=''
                description={`We're passionate about using technology to create a better world for authors and readers. Join us on this exciting journey and experience the future of digital book publishing today.`}/>
             </SectionContent>
          </SectionWrapper>
        </Layout>
        <NewsletterLink />
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: auto;
  left: 0;
  top: 0;
  background-color: ${palette.white};
`;

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0 auto 100px auto;
  max-width: 1200px;
  padding: 0 10px;
`;

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Adjust alignment as needed */
  margin-bottom: 50px;
`;

interface SectionContentProps {
  alignment?: 'left' | 'right';
}


const SectionContent = styled.div<SectionContentProps>`
  display: flex;
  flex-direction: column; /* Change to column */
  align-items: ${({ alignment }) => (alignment === 'right' ? 'flex-end' : 'flex-start')}; /* Adjust alignment as needed */
  width: 100%;
`;

interface ImageProps {
  src: string;
  alt: string;
  alignment?: 'left' | 'right' | 'center';
}

const Image = styled.img<ImageProps>`
  width: 400px; /* adjust the width as needed */
  align-self: center;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
`;


export default About;

