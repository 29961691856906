import '@rainbow-me/rainbowkit/styles.css'
import { useEffect } from 'react'
import './App.css'
//Routing Setup
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
//Container imports
import Bookshelf from 'containers/Bookshelf'
import Home from 'containers/Home'
import NotFoundPage from 'pages/NotFoundPage'
import BookPage from 'pages/BookPage'
import Footer from 'components/Footer'
import Header from 'components/home/Header'
import Promo from 'components/Promo'
import { getConnectedWallet } from 'interact'
import { useDispatch } from 'react-redux'
import { ThemeProvider } from 'styled-components'

import { connect } from 'app/reducers/walletReducer'
import { defaultTheme } from './theme'
import { Web3Provider } from 'providers/Web3Provider'
import Privacy from 'pages/Privacy'
import Terms from 'pages/Terms'
import Contact from 'pages/Contact'
import About from 'pages/About'

//const url = 'https://ipfs.io/ipfs/QmQ2DvmDABfHNCYfgKWfK2n3EupcBB6wn2HgrEBzuQRbaT?filename=Ruin.epub'

function App() {
  const dispatch = useDispatch()

  const init = async () => {
    const result = await getConnectedWallet()
    if (result.length > 0) {
      dispatch(connect(result[0]))
    }
  }

  useEffect(() => {
    init()
  }, [])

  return (
    <Web3Provider>
      <ThemeProvider theme={defaultTheme}>
        <Router>
          <Promo />
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/bookshelf" element={<Bookshelf />} />
            <Route path="/books/:id" element={<BookPage />} />
            <Route path="*" element={<NotFoundPage />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/about" element={<About />} />
          </Routes>
          <Footer />
        </Router>
      </ThemeProvider>
    </Web3Provider>
  )
}

export default App

// <ReaderWrapper url={url} loadingView />
