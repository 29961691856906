import { FC } from 'react'

export const DiscordIcon: FC<{
  color?: string
  size?: number
}> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    version="1.1"
    id="Capa_1"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style={
      {
        enableBackground: 'new 0 0 512 512',
      } as any
    }
    xmlSpace="preserve"
    width={props.size}
    height={props.size}
  >
    <g>
      <path
        fill={props.color}
        d="M227.9,270.06c0,20.56-14.73,37.29-33.25,37.29c-18.23,0-33.25-16.73-33.25-37.29c0-20.55,14.73-37.29,33.25-37.29   C213.31,232.77,228.19,249.66,227.9,270.06z"
      />
      <path
        fill={props.color}
        d="M350.83,270.06c0,20.56-14.58,37.29-33.25,37.29c-18.23,0-33.25-16.73-33.25-37.29c0-20.55,14.73-37.29,33.25-37.29   C336.25,232.77,351.12,249.66,350.83,270.06z"
      />
      <path
        fill={props.color}
        d="M256,0C114.62,0,0,114.62,0,256s114.62,256,256,256s256-114.62,256-256S397.38,0,256,0z M438.91,350.25   c-31.64,23.24-62.3,37.35-92.53,46.7c-0.48,0.15-1-0.03-1.3-0.44c-6.98-9.72-13.32-19.97-18.88-30.73   c-0.32-0.63-0.03-1.39,0.63-1.64c10.07-3.81,19.65-8.36,28.87-13.74c0.73-0.43,0.77-1.47,0.1-1.97c-1.95-1.45-3.89-2.98-5.74-4.51   c-0.35-0.28-0.82-0.34-1.21-0.15c-59.84,27.65-125.39,27.65-185.94,0c-0.39-0.17-0.86-0.11-1.19,0.17   c-1.85,1.52-3.79,3.04-5.73,4.49c-0.67,0.5-0.62,1.54,0.11,1.97c9.22,5.28,18.8,9.93,28.86,13.75c0.66,0.25,0.97,1,0.64,1.63   c-5.43,10.78-11.78,21.02-18.89,30.74c-0.31,0.4-0.82,0.58-1.3,0.43c-30.09-9.35-60.74-23.46-92.39-46.7   c-0.26-0.21-0.45-0.53-0.48-0.87c-6.44-69.03,6.7-138.92,54.66-210.58c0.12-0.19,0.29-0.34,0.5-0.42   c23.59-10.84,48.88-18.81,75.3-23.36c0.48-0.07,0.96,0.15,1.21,0.57c3.27,5.79,7,13.2,9.53,19.26c27.85-4.25,56.14-4.25,84.58,0   c2.52-5.93,6.12-13.47,9.37-19.26c0.25-0.44,0.73-0.66,1.21-0.57c26.44,4.56,51.73,12.54,75.31,23.36   c0.21,0.08,0.38,0.23,0.48,0.44c41.78,61.45,62.42,130.77,54.7,210.58C439.36,349.74,439.19,350.04,438.91,350.25z"
      />
    </g>
  </svg>
)
