import styled from 'styled-components'

const ControlBtn = ({ message, onClick, disabled }: Props) => {
  return (
    <Btn onClick={() => onClick()} type="button" disabled={disabled}>
      {message}
    </Btn>
  )
}

const Btn = styled.button<{ disabled?: boolean }>`
  align-items: center;
  background-color: transparent;
  border-radius: 20px;
  border: 2px ${(props) => props.theme.palette.primary} solid;
  color: black;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  display: flex;
  font-size: 1rem;
  justify-content: center;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  outline: none;
  padding: 0 16px;
  padding: 10px 20px;
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
  transition: background-color .25s, color .25s;

  &:focus,
  &:hover {
    background-color: ${(props) => props.theme.palette.primary};
    color: white;
  }

  &:first-child {
    margin-left: 8px;
  }
`

interface Props {
  message: string
  onClick: (value?: boolean) => void
  disabled?: boolean
}

export default ControlBtn
