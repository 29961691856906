import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import Page from 'Reader/types/page'

export interface BookState {
  currentLocation: Page | null
  url: string
}

const initialState: BookState = {
  currentLocation: null,
  url: '',
}

export const bookSlice = createSlice({
  name: 'book',
  initialState,
  reducers: {
    updateBookUrl: (state, action: PayloadAction<string>) => {
      state.url = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { updateBookUrl } = bookSlice.actions

export default bookSlice.reducer
