import React from 'react';
import styled from 'styled-components';
import YouTube from 'react-youtube';

const GenericYT = () => {
  // Add the YouTube video ID for the video you want to embed
  const videoId = 'skXXKVKm0XY';

  return (
    <Wrapper>
      <Container>
        <VideoContainer>
          <YouTube videoId={videoId} />
        </VideoContainer>
      </Container>
    </Wrapper>
  );
};

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 1200px;
  position: relative;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const Wrapper = styled.div`
  padding: 0 10px;
`;

const Message = styled.div`
  background-color: white;
  font-size: 0.75rem;
  padding: 40px 20px;
  width: 100%;

  @media (min-width: 768px) {
    width: 50%;
  }
`;

const Shadow = styled.div`
  background-color: rgba(0, 0, 0, 0.25);
  bottom: -20px;
  left: 20px;
  position: absolute;
  right: -20px;
  top: 20px;
  z-index: -1;
`;

const Caption = styled.div`
  color: ${(props) => props.theme.palette.primary};
  font-weight: bold;
  font-size: 1rem;
  text-transform: uppercase;
`;

const Title = styled.h2`
  font-family: Primary;
  font-size: 4rem;
  line-height: 4rem;
  margin: 0;
  text-transform: uppercase;
`;

const Author = styled.h3`
  color: gray;
  font-family: Primary;
  font-size: 4rem;
  line-height: 4rem;
  margin: 0;
  text-transform: uppercase;
`;

const Tagline = styled.p`
  font-size: 1.75rem;
`;

const Link = styled.a`
  border-bottom: 1px black solid;
  color: black;
  font-size: 1rem;
  transition: border-bottom-color 0.5s, color 0.5s;

  &:hover {
    border-bottom-color: ${(props) => props.theme.palette.primary};
    color: ${(props) => props.theme.palette.primary};
  }
`;

const VideoContainer = styled.div`
  margin-top: 0;
  width: 80%;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
  

  @media (min-width: 768px) {
    margin-top: 0;
    width: 50%;
    margin-left: auto; /* Center justify the video container */
    margin-right: auto;
  }

  iframe {
    width: 100%;
  }
`;

export default GenericYT;
