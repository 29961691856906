import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface WalletState {
  address: string
}

const initialState: WalletState = {
  address: '',
}

export const walletSlice = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    connect: (state, action: PayloadAction<string>) => {
      state.address = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { connect } = walletSlice.actions

export default walletSlice.reducer
