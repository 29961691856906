import { usePrepareContractWrite, useContractWrite, useWaitForTransaction } from 'wagmi'
import { useDebounce } from 'usehooks-ts'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import books from 'data/books'
import { BigNumber } from 'ethers';
import { useAccount, useNetwork } from 'wagmi'
import { polygonMumbai } from '@wagmi/chains'
import Slider from 'react-input-slider'
import ControlBtn from 'components/home/ControlBtn'


export const BuyBooks = () => {
    const {address} = useAccount()
    const isConnected = Boolean(address)
    const { chain } = useNetwork()
    const isOnPolygon = chain?.id === 80001 //Update for polygon mainnet in production
    console.log(isOnPolygon)
    const params = useParams<{ id: string }>()
    const book = books.find((book) => book.tokenId === Number(params.id))
    if (!book) {
        return null
    }
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [numBooks, setNumBooks] = useState('0')
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const debouncedNumBooks = useDebounce(numBooks, 500)
    const numBooksString = debouncedNumBooks.toString()
    const bigNumBooks = BigNumber.from(numBooksString)
    const bigSaleIndex = BigNumber.from(book.saleIndex)
    const bigGasLimit = BigNumber.from(500000)
    const sendValue = bigNumBooks.mul(BigNumber.from(book.cost)).mul(BigNumber.from(10).pow(18))
    console.log(bigNumBooks)


    //if (chain !== polygonMumbai) {
    //    throw new Error(`Please connect your wallet to the Polygon Mumbai network to use this feature.`)
    //}
    
    const {
        config,
        error: prepareError,
        isError: isPrepareError,
      // eslint-disable-next-line react-hooks/rules-of-hooks
      } = usePrepareContractWrite({
        address: book.saleContract,
        abi: [
          {
            name: 'buyToken',
            type: 'function',
            stateMutability: 'payable',
            inputs: [{internalType:"uint256",name:"_saleIndex",type:"uint256"},{internalType:"uint256",name:"_numBooks",type:"uint256"}],
            outputs: [],
          },
        ],
        functionName: 'buyToken',
        chainId: polygonMumbai.id,
        overrides: {
            from: address,
            value: sendValue,
            gasLimit: bigGasLimit,
          },
        args: [bigSaleIndex, bigNumBooks],
        enabled: Boolean(debouncedNumBooks),
      })
    console.log(config)

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { data, error, isError, write } = useContractWrite(config)

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { isLoading, isSuccess } = useWaitForTransaction({
        hash: data?.hash,
      })
    return(
    <form
              onSubmit={(e) => {
                e.preventDefault()
                write?.()
              }}
            >
                <label htmlFor="_numBooks">Number of Books: {numBooks}</label>
                {/*<input
                    id="_numBooks"
                    onChange={(e) => setNumBooks(e.target.value)}
                    placeholder='1'
                    value={numBooks}
                  />*/}
                <div>
                  <Slider
                  axis='x'
                  xmin={0}
                  xstep={1}
                  xmax={book.maxBooks}
                  x={Number(numBooks)}
                  onChange={({x}) => setNumBooks(x.toString())}
                  styles={{active: {backgroundColor: '#a72609'}}}
                  />
                  {!isConnected && (
                    <ControlBtn message='connect your wallet to buy.' disabled onClick={() => {}}/>
                  )}

                  {isConnected && !isOnPolygon && (
                    <ControlBtn message='switch to the polygon network to buy.' disabled onClick={() => {}}/>
                  )}  

                  {isConnected && isOnPolygon && write && (
                    <ControlBtn
                      message={`${(Number(numBooks))*(book.cost)} MATIC`}
                      onClick={() => {
                      write?.()
                      }}
                    />
                  )}

                  {isConnected && isOnPolygon && !write || isLoading && (
                  <ControlBtn message='Please wait...' disabled onClick={() => {}}/>
                  )}
                </div>  
                {/*<button disabled={!write || isLoading} >
                    {isLoading ? 'Transaction Pending...' : 'Buy Books'}
                  </button>*/}
                {isSuccess && (
                    <div>
                        Successfully purchased your book! Navigate to your bookshelf to view your book.
                        <div>
                            <a href={`https://mumbai.polygonscan.com/tx/${data?.hash}`}>Mumbai Polygonscan</a>
                        </div>
                    </div>
      )}
      {(isPrepareError || isError) && (
        <div>Error: {(prepareError || error)?.message}</div>
      )}
    </form>
    )

  }