import { createAlchemyWeb3 } from '@alch/alchemy-web3'
import { Network, Alchemy } from 'alchemy-sdk'
import { useState } from 'react'
import contracts from './Book_contract_and_ABI_data/contracts.json'




// Alchemy API settings: update for mainnet when production ready
const settings = {
  apiKey: process.env.REACT_APP_ALCHEMY_TESTNET,
  network: Network.ETH_GOERLI
}
const alchemy = new Alchemy(settings)


export const connectWallet = async () => {
  if (typeof window.ethereum !== 'undefined') {
    const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' })
    return (accounts)
  }
}

export const getConnectedWallet = async () => {
  if (typeof window.ethereum !== 'undefined') {
    const accounts = await window.ethereum.request({ method: 'eth_accounts' })

    return (accounts)
  }
}

export const LoadHash = async (address: string, contract: string, chain: number) => {
  //select alchemyKey based on connected network
  const alchemyKey = chain === 80001 ? process.env.REACT_APP_ALCHEMY_WEBSOCKET_POLYTESTNET : process.env.REACT_APP_ALCHEMY_WEBSOCKET_TESTNET
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const web3Alchemy = createAlchemyWeb3(alchemyKey!)
  //Ensure ABI json file uses only lowercase characters to match the Alchemy contract pull from the nft
  const contractABI = require(`./Book_contract_and_ABI_data/${contract}.json`)
  const BookContract = new web3Alchemy.eth.Contract(
    contractABI,
    contract
  )
  const keyHash = await BookContract.methods.viewHash().call({
    from: address
  })
  return (keyHash)
}

export const getMetaData = async (tokenUri: string, tokenId: string) => {
  const tokenMetaData = fetch(`${tokenUri}/${tokenId}.json`)
    .then(async (response) => {
      if (!response.ok) {
        throw new Error('Network response was not OK')
      }
      return response.json()
    })
    .catch((error) => {
      console.error('There has been a problem with your fetch operation:', error)
    })
  return (tokenMetaData)
}