import ControlBtn from "components/home/ControlBtn"
import Modal from 'react-modal'
import { usePrepareContractWrite, useContractWrite, useWaitForTransaction } from 'wagmi'
import { useState } from 'react'
import { useAccount, useNetwork } from 'wagmi'
import { BigNumber } from 'ethers'
import { useDebounce } from "usehooks-ts"


const SendBookButton = ({ contractAddress, tokenId }: Props) => {
    const {address} = useAccount()
    const { chain } = useNetwork()
    const [destinationAddress, setDestinationAddress] = useState('')
    const [numBooks, setNumBooks] = useState('0')
    const [isOpen, setIsOpen] = useState(false)

    const debouncedNumBooks = useDebounce(numBooks, 500)
    const bigDebounceNumBooks = BigNumber.from(debouncedNumBooks || '0')
    const bigTokenId = BigNumber.from(tokenId)
    const bigGasLimit = BigNumber.from(500000)
    const oxContractaddress = contractAddress?.replace('0x', '0x') as `0x${string}`
    const oxDestinationaddress = destinationAddress?.replace('0x', '0x') as `0x${string}`
    if (!address) {
        return null
    }

    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
        },
      }

    const {
        config,
        error: prepareError,
        isError: isPrepareError,
      // eslint-disable-next-line react-hooks/rules-of-hooks
      } = usePrepareContractWrite({
        address: oxContractaddress,
        abi: [
          {
            name: 'safeTransferFrom',
            type: 'function',
            stateMutability: 'nonpayable',
            inputs: [{internalType:"address",name:"from",type:"address"},
                {internalType:"address",name:"to",type:"address"},
                {internalType:"uint256",name:"id",type:"uint256"},
                {internalType:"uint256",name:"amount",type:"uint256"},
                {internalType:"bytes",name:"data",type:"bytes"}],
            outputs: [],
          },
        ],
        functionName: 'safeTransferFrom',
        chainId: chain?.id,
        overrides: {
            gasLimit: bigGasLimit,
          },
        args: [address, oxDestinationaddress, bigTokenId, bigDebounceNumBooks, '0x00'],
        enabled: Boolean(debouncedNumBooks),
      })
    console.log(config)

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { data, error, isError, write } = useContractWrite(config)

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { isLoading, isSuccess } = useWaitForTransaction({
        hash: data?.hash,
      })

    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <ControlBtn message = 'Send' onClick={() => setIsOpen(true)}/>
            <Modal
            isOpen={isOpen}
            onRequestClose={() => setIsOpen(false)}
            style={customStyles}
            contentLabel="Send NFT Modal"
             >
                <h2>Send Book</h2>
                <form
                    onSubmit={(e) => {
                    e.preventDefault()
                    write?.()
                    }}
                >
                <div>
                <label >Recipient Address:</label>
                    <input
                        id="RECIPIENT_ADDRESS"
                        onChange={(e) => setDestinationAddress(e.target.value)}
                        placeholder="0x..."
                        value={destinationAddress}
                    />
                </div>
                <div>
                <label >Number of Books:</label>
                    <input
                        id="numBooks"
                        onChange={(e) => setNumBooks(e.target.value)}
                        placeholder="0"
                        value={numBooks}
                    />
                </div>
                <button disabled={!write || isLoading}>
                    {isLoading ? 'Sending...' : 'Send'}
                </button>
                {isSuccess && (
                    <div>
                    Successfully sent your Book!
                    <div>
                    <a href={`https://etherscan.io/tx/${data?.hash}`}>Etherscan</a>
                    </div>
                    </div>
                )}
                </form>
                {(isPrepareError || isError) && (
                <div>Error: {(prepareError || error)?.message}</div>
                )}
             </Modal>
        </div>
    )

}

interface Props {
    contractAddress: string
    tokenId: number
}

export default SendBookButton