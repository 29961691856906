import { FC } from 'react';

export const TelegramIcon: FC<{
  color?: string;
  size?: number;
}> = (props) => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    width={props.size}
    height={props.size}
  >
    <g>
      <line
        x1="22"
        y1="2"
        x2="11"
        y2="13"
        fill="none"
        stroke={props.color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <polygon
        points="22 2 15 22 11 13 2 9 22 2"
        fill="none"
        stroke={props.color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

