import { defaultTheme } from '../theme'
import { Icon } from '../types/icon'
import { FC } from 'react'

export const ContentsIcon: FC<Icon> = ({
  color = defaultTheme.palette.primary,
  size = 32,
}) => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
  >
    <path
      d="m1 17h6v6h-3a3 3 0 0 1 -3-3zm8 6h11a3 3 0 0 0 3-3v-3h-14zm0-8h14v-6h-14zm-8-11v3h6v-6h-3a3 3 0 0 0 -3 3zm0 11h6v-6h-6zm19-14h-11v6h14v-3a3 3 0 0 0 -3-3z"
      fill={color}
    />
  </svg>
)
