import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum'
import { Web3Modal } from '@web3modal/react'
import { alchemyProvider } from 'wagmi/providers/alchemy'
import { FC, PropsWithChildren, useEffect, useRef, useState } from 'react'
import { WagmiConfig, configureChains, createClient } from 'wagmi'
import { goerli, mainnet, polygonMumbai, polygon } from 'wagmi/chains'

const chains = [
  mainnet, goerli, polygonMumbai, polygon
];
const projectId = `${process.env.REACT_APP_WALLET_CONNECT_PROJECTID}`

const { provider } = configureChains(chains, [alchemyProvider({apiKey: `${process.env.REACT_APP_ALCHEMY_POLYTESTNET}` }), w3mProvider({ projectId })])

const getWagmiClient = () =>
  createClient({
    autoConnect: true,
    connectors: w3mConnectors({ chains, version: 2, projectId }),
    provider,
  })

const ethereumClient = new EthereumClient(getWagmiClient(), chains)
console.log(ethereumClient)





export const Web3Provider: FC<PropsWithChildren> = ({ children }) => {
    const init = useRef(false)
    const [client, setClient] = useState<ReturnType<typeof getWagmiClient>>()
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState<Error>()
  
    useEffect(() => {
      if (init.current) {
        return
      }
  
      setIsLoading(true)
      try {
        const wagmiClient = getWagmiClient()
        setClient(wagmiClient)
        setIsLoading(false)
        init.current = true
      } catch (error: unknown) {
        setIsLoading(false)
        setError(error as Error)
      }
    }, [])
  
    if (error) {
      return <div>Error: {error.message}</div>
    }
  
    if (isLoading || !client) {
      return <div>Loading...</div>
    }
  
    return (
      <WagmiConfig client={client}>
        <Web3Modal projectId={projectId} ethereumClient={ethereumClient} defaultChain={polygonMumbai} themeVariables={{'--w3m-accent-color': '#a72609', '--w3m-background-color': '#a72609'}} enableNetworkView={true}></Web3Modal>
        {children}
      </WagmiConfig>
    )
  }
  



