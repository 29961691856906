import styled from 'styled-components';

const FeaturedArticles = () => {
  const articles = [
    {
      date: 'January 21, 2023',
      title: 'You already own nothing, and you shouldn’t like it',
      image: '/images/articles/you_dont_own_your_own_books.png',
      link:
        'https://medium.com/@Readeum/you-already-own-nothing-and-you-shouldnt-like-it-ac8662150276',
    },
    {
      date: 'February 16, 2023',
      title: 'Readeum: For Authors',
      image: '/images/articles/Readeum_for_authors.png',
      link:
        'https://medium.com/@Readeum/readeum-for-authors-c51438694de2',
    },
    /*{
      date: 'February 20, 2023',
      title:
        'Discovering the Magic of Oz: Exploring the Symbolism and Themes of The Wizard of Oz',
      image: '/images/articles/wizard-of-oz.png',
      link: 'https://medium.com/your-article-link-2',
    },
    {
      date: 'February 23, 2023',
      title:
        'Unlocking the Mind of the Master Detective: The Adventures of Sherlock Holmes',
      image: '/images/articles/sherlock-holmes.png',
      link: 'https://medium.com/your-article-link-3',
    },*/
  ];

  return (
    <>
      <Wrapper>
        <Container>
          <Title>Learn More</Title>
          <List>
            {articles.map((article) => (
              <ListItem key={article.title}>
                <a
                  href={article.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <ArticleImage src={article.image} alt={article.title} />
                </a>
                <ArticleTitle>
                  <a
                    href={article.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {article.title}
                  </a>
                </ArticleTitle>
                <ArticleDate>{article.date}</ArticleDate>
              </ListItem>
            ))}
          </List>
        </Container>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.footer`
  background-color: #f9f9f9;
  padding: 100px 0;
`;

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 1200px;
`;

const Title = styled.h2`
  font-family: Primary;
  text-transform: uppercase;
`;

const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  list-style: none;
  margin: 40px 0;
  padding: 0;
`;

const ListItem = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
  max-width: 300px;
`;

const ArticleImage = styled.img`
  width: 100%;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
`;

const ArticleTitle = styled.h3`
  font-size: 1.25rem;
  margin: 10px 0;

  a {
    text-decoration: none;
    color: #333;
  }
`;

const ArticleDate = styled.div``;

export default FeaturedArticles;
