import styled from 'styled-components'
import books from 'data/books'
import palette from 'lib/styles/palette'

import BookCover from '../BookCover'
import Heading from 'components/Heading'
import ShadowHeading from 'components/ShadowHeading'
import Hero from 'components/Hero'
import HeroYT from 'components/HeroYT'
import GenericYT from 'components/GenericYT'
import FeaturedArticles from 'components/FeaturedBlogPosts'
import Newsletter from 'components/Newsletter'
import NewsletterSendFox from 'components/NewsletterSendFox'
import Social from 'components/Social'
import NewsletterLink from 'components/NewsletterLink'

const HomeBody = () => {
  return (
    <>
      <Wrapper>
        <HeroYT />
        <NewsletterLink />
        <Layout>
        <ShadowHeading
            title="Readeum Bookshelf and eReader."
            description="This is the first in a series of videos demonstrating the Readeum bookshelf and eReader dApp.  Stay tuned as future videos will demo the ability to send books to your friends and show you how to bridge between Polygon and Ethereum blockchains.  Subscribe and connect with us on our socials so you never miss an update. "/>
            <GenericYT />
          <ShadowHeading
            title="Readeum, for Readers."
            description="Welcome to the future of book ownership! Our ebook publishing platform is revolutionizing the way readers and NFT collectors interact with their favorite books. With our cutting-edge blockchain technology, you can finally own your books in a way that's secure, transparent, and truly yours. 

            Our platform allows you to buy and sell books on the secondary market, giving you the freedom to build your own personal library and share your favorite reads with others. And with the ability to move your book NFTs to the ethereum chain, you can take your ownership to the next level. 
            
            But that's not all. Our dApp allows you to buy and read your books without ever having to provide a shred of personal information to Readeum. And with multiple cover designs of varying rarity, you'll have the chance to get your hands on a truly unique and valuable piece of literary art. 
            
            Whether you're a reader looking for a new way to own your books or an NFT collector searching for the next big thing, our platform has something for everyone. Join us today and experience the future of book ownership!"
          />
          <ShadowHeading
            title="Readeum, for Authors."
            description="Welcome to Readeum, the full ebook publishing platform that puts authors in control like never before. With low royalties and fees, you can keep more of your hard-earned profits while reaching a new and wider audience.
            But that's not all. Our platform also gives you the power to control scarcity, ensuring that your books remain valuable and in demand. And with the ability to use the power of blockchain to build community with your biggest fans, you can cultivate a loyal following that will support you throughout your career.
            At Readeum, we understand the challenges that authors face in today's competitive market. That's why we offer a range of tools and services to help you succeed. And with our commitment to transparency and fairness, you can trust that you're getting the best deal possible.
            But don't just take our word for it. Check out our recent Medium article (link below), where we dive deeper into how Readeum can help authors build their careers and connect with readers.
            Join the Readeum community today and take your writing career to the next level. Want more information now? Navigate to the contact page and reach out.  We can't wait to see what you'll create!"
          />
          
          {/*<List>
            {books.slice(0, 6).map((book) => (
              <ListItem key={book.tokenId}>
                <BookCover
                  imageUrl={`/images/covers/${book.tokenId}.png`}
                  tokenId={book.tokenId}
                />
              </ListItem>
            ))}
            </List>
          <ShadowHeading
            title="New Releases"
            description="Unlock new worlds of imagination and knowledge."
          />
          <List>
            {books.slice(6, 12).map((book) => (
              <ListItem key={book.tokenId}>
                <BookCover
                  imageUrl={`/images/covers/${book.tokenId}.png`}
                  tokenId={book.tokenId}
                />
              </ListItem>
            ))}
          </List>
          <Heading
            title="On Sale Now!"
            description="Your newest great read awaits."
          />
          <List>
            {books.slice(12,13).map((book) => (
              <ListItem key={book.tokenId}>
                <BookCover
                  imageUrl={`/images/covers/${book.tokenId}.gif`}
                  tokenId={book.tokenId}
                  hideTitle
                />
              </ListItem>
            ))}
          </List>
           <Heading
            title="Current Bestsellers"
            description="Experience the stories that have captivated readers everywhere."
          />
          <List>
            {books.slice(12, 18).map((book) => (
              <BookCover
                key={book.tokenId}
                imageUrl={`/images/covers/${book.tokenId}.jpg`}
                title={book.title}
                tokenId={book.tokenId}
              />
            ))}
          </List>
          <Heading
            title="Trending"
            description="Join the conversation with the books everyone is talking about."
          />
          <List>
            {books.slice(18, 24).map((book) => (
              <BookCover
                key={book.tokenId}
                imageUrl={`/images/covers/${book.tokenId}.jpg`}
                title={book.title}
                tokenId={book.tokenId}
              />
            ))}
          </List> */}
        </Layout>
      </Wrapper>
      <FeaturedArticles />
      <NewsletterLink />
    </>
  )
}

const Wrapper = styled.div`
  width: 100%;
  height: auto;
  left: 0;
  top: 0;
  background-color: ${palette.white};
`

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0 auto 100px auto;
  max-width: 1200px;
  padding: 0 10px;
`

const List = styled.div`
  display: flex;
  justify-content: space-between;
  list-style: none;
  margin: 0;
  padding: 0;
`

const ListItem = styled.li`
  margin: 0;
  padding: 0;
  width: calc(100% / 6.5);
`

export default HomeBody
