import { defaultTheme } from '../theme'
import { Icon } from '../types/icon'
import { FC } from 'react'

export const HighlightsIcon: FC<Icon> = ({
  color = defaultTheme.palette.primary,
  size = 32,
}) => (
  <svg
    id="Capa_1"
    enableBackground="new 0 0 512.354 512.354"
    viewBox="0 0 512.354 512.354"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
  >
    <path
      fill={color}
      d="m416.013 457.001h-144c-8.284 0-15 6.716-15 15s6.716 15 15 15h144c8.284 0 15-6.716 15-15s-6.716-15-15-15z"
    />
    <path
      fill={color}
      d="m66.692 399.962-62.212 61.36c-9.52 9.39-2.863 25.679 10.533 25.679h97c3.978 0 7.793-1.58 10.606-4.393l13.359-13.359z"
    />
    <path
      fill={color}
      d="m106.787 280.057c-16.745 17.635-23.472 42.997-17.558 66.655 2.557 10.228 1.337 20.958-3.092 30.269l72.898 72.898c9.312-4.43 20.041-5.649 30.268-3.092 23.682 5.921 49.012-.829 66.643-17.57z"
    />
    <path
      fill={color}
      d="m497.513 90.178-43.213-46.814c-20.73-22.458-55.528-24.106-78.296-3.868l-247.421 219.93 148.147 148.147 220.964-241.679c19.602-21.44 19.552-54.338-.181-75.716z"
    />
  </svg>
)
