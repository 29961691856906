import styled from 'styled-components'

const NotFoundPage = () => (
  <Wrapper>
    <h2>Not Found</h2>
    <p>Why did the book go missing? Because it was checked out!</p>
    <img src="/images/icons/lost-icon.svg" />
    <p><a href="/">Click here</a> to go home.</p>
  </Wrapper>
)

const Wrapper = styled.div`
  margin: 0 auto;
  max-width: 1200px;
  text-align: center;
`

export default NotFoundPage
