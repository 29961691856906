import BookList from 'components/BookList'
import ControlBtn from 'components/home/ControlBtn'
import books from 'data/books'
import { BuyBooks } from 'data/BuyBooks'
import { marked } from 'marked'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { useNetwork, useAccount } from 'wagmi'


import NotFoundPage from './NotFoundPage'

const BookPage = () => {
  const {address} = useAccount()
  console.log(address)
  const {chain} = useNetwork()
  console.log(chain)
  const params = useParams<{ id: string }>()

  const [description, setDescription] = useState('')

  const book = books.find((book) => book.tokenId === Number(params.id))

  const init = async () => {
    if (!book) {
      return
    }
    const newDescription = await fetch(`/books/${book.tokenId}/description.md`)
    if (newDescription) {
      const descriptionFormatted = marked.parse(await newDescription.text())
      setDescription(descriptionFormatted)
    }
  }

  useEffect(() => {
    init()
  }, [book])

  if (!book) {
    return <NotFoundPage />
  }

  let content = null
  if (book.status === 'onSale') {
    content = (
      <Wrapper>
        <Layout>
          <Left>
            <h2>{book.title}</h2>
            <p>{book.author}</p>
            <div>
              <BuyBooks />
            </div>
            {description && (
              <div dangerouslySetInnerHTML={{ __html: description }} />
            )}
          </Left>
          <Right>
            <BookContainer>
              <BookImage src={`/images/covers/${book.tokenId}.gif`} />
            </BookContainer>
          </Right>
        </Layout>
        <BookList
          title="Suggested Books"
          description="Elevate your reading list with our top recommendations"
          ignoreTokenId={book.tokenId}
        />
      </Wrapper>
    )
  } else if (book.status === 'none') {
    content = (
      <Wrapper>
        <Layout>
          <Left>
            <h2>{book.title}</h2>
            <p>{book.author}</p>
            <ControlBtn message={'Sample'} onClick={() => {}} />
            {description && (
              <div dangerouslySetInnerHTML={{ __html: description }} />
            )}
          </Left>
          <Right>
            <BookContainer>
              <BookImage src={`/images/covers/${book.tokenId}.png`} />
              <BookLogoContainer>
                <img src={`/images/covers/${book.tokenId}-logo.svg`} />
              </BookLogoContainer>
            </BookContainer>
          </Right>
        </Layout>
        <BookList
          title="Suggested Books"
          description="Elevate your reading list with our top recommendations"
          ignoreTokenId={book.tokenId}
        />
      </Wrapper>
    )
  }

  return content
}








const Wrapper = styled.div`
  margin: 0 auto 40px auto;
  max-width: 1200px;
`

const Layout = styled.div`
  display: flex;
`

const Left = styled.div`
  flex-grow: 1;
`

const Right = styled.div`
  margin-left: 40px;
`

const BookContainer = styled.div`
  position: relative;
`

const BookImage = styled.img`
  border-radius: 15px;
  margin-top: 20px;
  width: 300px;
`

const BookLogoContainer = styled.div`
  bottom: 30px;
  left: 30px;
  position: absolute;
  right: 30px;

  img {
    width: 100%;
  }
`

export default BookPage
