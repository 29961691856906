import styled from 'styled-components';

const NewsletterLink = () => {
  const handleSignup = () => {
    // Replace 'YOUR_SIGNUP_URL' with the actual URL for signing up
    const signupUrl = 'https://sendfox.com/readeum';
    window.open(signupUrl, '_blank');
  };

  return (
    <Wrapper>
      <Container>
        <MessageContainer>
          <Message>Never miss a Readeum Update</Message>
        </MessageContainer>
        <Button onClick={handleSignup}>Sign Up</Button>
      </Container>
    </Wrapper>
  );
};

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 1200px;
`;

const Wrapper = styled.div`
  background-color: ${(props) => props.theme.palette.primary};
  color: black;
  padding: 50px 10px;
`;

const MessageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
`;

const Message = styled.div`
  color: white;
  font-family: Primary;
  font-size: 2rem;
  text-transform: uppercase;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
`;

const Button = styled.button`
  background: none;
  background-color: black;
  border-radius: 20px;
  border: none;
  color: white;
  font-family: Primary;
  font-weight: bold;
  padding: 20px;
  text-transform: uppercase;
  width: 95px;

  @media (max-width: 768px) {
    border-radius: 20px;
    margin-left: 0;
  }
`;

export default NewsletterLink;
