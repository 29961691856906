import styled from 'styled-components'
import { defaultTheme } from 'theme'
import Logo from './home/Logo'

const BookCover = ({
  hideTitle,
  imageUrl,
  tokenId,
}: {
  hideTitle?: boolean
  imageUrl: string
  tokenId: number
}) => (
  <Wrapper href={`/books/${tokenId}`}>
    <Container>
      <Image src={imageUrl} crossOrigin='anonymous' />
      <LogoContainer>
        <Logo color={defaultTheme.palette.primary} hideText size={48} />
      </LogoContainer>
      {!hideTitle && (
        <BookLogoContainer>
          <img src={`/images/covers/${tokenId}-logo.svg`} />
        </BookLogoContainer>
      )}
      {/* <Shadow /> */}
      {/* <Spine /> */}
    </Container>
  </Wrapper>
)

const Wrapper = styled.a`
  align-items: flex-end;
  background: none;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  &:hover {
    img {
      transform: translateY(-10);
    }
  }
`

const Container = styled.div`
  border-radius: 15px;
  display: inline-block;
  position: relative;
  overflow: hidden;

  &:hover {
    > img {
      transform: scale(1.1);
    }
  }
`

const Image = styled.img`
  display: block;
  transition: transform 0.25s;
  width: 100%;
`

const LogoContainer = styled.div`
  left: 0;
  position: absolute;
  top: 0;
`

const BookLogoContainer = styled.div`
  bottom: 20px;
  left: 20px;
  position: absolute;
  right: 20px;

  img {
    width: 100%;
  }
`

const Shadow = styled.div`
  box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.5);
  bottom: 0;
  content: ' 🦄';
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 9999;
`

const Spine = styled.div`
  box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.5);
  bottom: 0;
  content: ' 🦄';
  left: 6px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 9999;
`

const Title = styled.h3`
  font-size: 0.75rem;
  height: 30px;
  text-align: left;
  width: 100%;
`

export default BookCover
