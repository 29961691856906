import { FC, useEffect, useState } from 'react'
import ControlBtn from './ControlBtn'
import { Web3Button, Web3NetworkSwitch } from '@web3modal/react'
import { useAccount } from 'wagmi'

const Wallet: FC = () => {
  const { address, isConnected } = useAccount()

  const [addressFormatted, setAddressFormatted] = useState('')

  useEffect(() => {
    if (!address) {
      setAddressFormatted('')
      return
    }

    setAddressFormatted(`${address.slice(0, 6)}...${address.slice(-4)}`)
  }, [address])

  return (
    /*<Web3NetworkSwitch/>*/
    <Web3Button balance={'show'} icon={'hide'}/> 
  )
}


export default Wallet
