import { Clickable } from 'components/Clickable'
import { ContentsIcon } from 'icons/ContentsIcon'
import { HighlightsIcon } from 'icons/HighlightsIcon'
import { SettingsIcon } from 'icons/SettingsIcon'
import styled from 'styled-components'

const MobileReaderControls = ({
  onNavToggle,
  onOptionToggle,
  onLearningToggle,
}: Props) => {
  return (
    <Wrapper>
      <Clickable onClick={onNavToggle}>
        <ContentsIcon color="#999999" size={24} />
      </Clickable>
      <Clickable onClick={onOptionToggle}>
        <SettingsIcon color="#999999" size={24} />
      </Clickable>
      <Clickable onClick={onLearningToggle}>
        <HighlightsIcon color="#999999" size={24} />
      </Clickable>

    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  left: 10px;
  position: fixed;
  bottom: 50px;
  z-index: 999;
`

interface Props {
  onNavToggle: (value?: boolean) => void
  onOptionToggle: (value?: boolean) => void
  onLearningToggle: (value?: boolean) => void
}

export default MobileReaderControls
