import styled from 'styled-components'

import Social from './Social'

const Promo = () => (
  <Wrapper>
    <Container>
      <Message>
        Follow our socials and sign up for our email newsletter for updates on the <b>FREE</b> first title minted from Readeum
      </Message>
      <Social />
    </Container>
  </Wrapper>
)

const Container = styled.div`
  align-items: center;
  display: flex;
  margin: 0 auto;
  max-width: 1200px;
`

const Wrapper = styled.div`
  background-color: black;
  color: white;
  padding: 0 10px;
`

const Message = styled.div`
  flex-grow: 1;
  font-size: 0.75rem;
`

export default Promo
