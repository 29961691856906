import { Alchemy, Network, NftNamespace } from 'alchemy-sdk'
import { useEffect, useState } from 'react'
import styled from 'styled-components'

import LoadingView from 'LoadingView'
import BookCard from 'components/bookshelf/BookCardV2'
import { useAccount, useNetwork } from 'wagmi'
import BookCover from 'components/BookCover'
import BookButtons from 'components/bookshelf/BookButtons'
import SendBookButton from 'components/bookshelf/SendBookButton'

const Bookshelf = () => {
  const { address } = useAccount(); // retrieve address
  const {chain} = useNetwork();//retrieve chain
  console.log (chain)

  const [isInitialized, setIsInitialized] = useState(false)
  const [nfts, setNfts] = useState<
    {
      contractAddress: string
      tokenId: number
      name: string
      description: string
      image: string
      title: string
      author: string
      tokenUri: string
      balance: number
      url: string
    }[]
  >([])
  // Ensure ca is lower case to interact with API
  const myBooks = ['0x7a89e81938ba818e5ce93007cbb7ee3c393ec7f0']

  const settings = {
    apiKey: chain?.id === 80001 ? process.env.REACT_APP_ALCHEMY_POLYTESTNET : process.env.REACT_APP_ALCHEMY_TESTNET,
    network: chain?.id === 80001 ? Network.MATIC_MUMBAI : Network.ETH_GOERLI // check chainId and set network accordingly
  }
  const alchemy = new Alchemy(settings)

  const getNftData = async (address: string) => {
    if (!address) {
      return
    }
    
    // updated for alchemy api
    const response = await alchemy.nft.getNftsForOwner(address, {"contractAddresses": myBooks})
    console.log(response)

    const ownedNfts = await Promise.all(
      response.ownedNfts
        .filter((nft) => myBooks.includes(nft?.contract?.address))
        .map(async (nft) => {
          const res = await fetch(`${nft.tokenUri?.raw.replace('gateway.pinata.cloud', 'cloudflare-ipfs.com')}`);
          //const res = await fetch(`${nft.tokenUri?.raw}`);
          const metadata = (await res.json()) as {
            name: string;
            description: string;
            image: string;
            attributes: {
              [key: string]: string;
            }[];
          };
    
          const accessToken = process.env.REACT_APP_PINATA_GATEWAY_ACCESS_TOKEN; // Read the access token from .env file
    
          // Replace 'gateway.pinata.cloud' with 'readeum.mypinata.cloud' in the image field
          const imageUrlReplaced = metadata.image.replace('gateway.pinata.cloud', 'readeum.mypinata.cloud');
    
          // Append the access token to the modified image URL
          const imageUrlWithToken = `${imageUrlReplaced}?pinataGatewayToken=${accessToken}`;
    
          return {
            contractAddress: nft.contract.address,
            tokenId: Number(nft.tokenId),
            name: metadata.name,
            description: metadata.description,
            image: imageUrlWithToken,
            title: metadata.attributes.find((attribute) => attribute.Title)!.Title,
            author: metadata.attributes.find((attribute) => attribute.author)!.author,
            tokenUri: nft.tokenUri!.raw,
            balance: nft.balance,
            url: metadata.attributes.find((attribute) => attribute.url)!.url,
          };
        })
    );
    

    console.log(ownedNfts)

    setNfts(ownedNfts)

    setIsInitialized(true)
  }

  useEffect(() => {
    if (!address) {
      return
    }

    getNftData(address)
  }, [address])

  if (!address) {
    return (
      <Wrapper>
        <h2>Connect Wallet</h2>
        <img src="/images/icons/bookshelf-icon.svg" />
        <p>Connect you wallet to view your bookshelf.</p>
      </Wrapper>
    )
  }

  if (!isInitialized) {
    return <LoadingView />
  }

  if (!nfts.length) {
    return (
      <Wrapper>
        <h2>Empty Bookshelf</h2>
        <img src="/images/icons/bookshelf-icon.svg" />
        <p>You don&apos;t own any books.</p>
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      <h2>My Bookshelf</h2>
      <List>
        {nfts.map((nft, index) => (
          <ListItem key={index}>
            <BookCover hideTitle imageUrl={nft.image} tokenId={nft.tokenId} />
            <BookButtons
              url={nft.url}
              contractAddress={nft.contractAddress}
              walletAddress={address}
            />
            <SendBookButton contractAddress={nft.contractAddress} tokenId={nft.tokenId} />
          </ListItem>
        ))}
      </List>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin: 0 auto 50px auto;
  max-width: 1200px;
  text-align: center;
`

const List = styled.ul`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: center;
  list-style: none;
  margin: 0;
  padding: 0;

  @media (max-width: 768px) {
    gap: 20px;
    justify-content: space-between;
  }

  @media (max-width: 576px) {
    gap: 10px;
    justify-content: flex-start;
  }
`

const ListItem = styled.li`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 0;
  padding: 0;
  width: calc(100% / 4);

  @media (max-width: 768px) {
    width: calc(100% / 2 - 20px);
  }

  @media (max-width: 576px) {
    width: calc(100% / 1 - 10px);
  }
`

export default Bookshelf
