import React from 'react';
import styled from 'styled-components';

const ShadowHeadingLink = ({ title, description }: Props) => (
  <>
    <Title>{title}</Title>
    {description && <Description>{parseDescription(description)}</Description>}
  </>
);

const parseDescription = (description: string) => {
    const emailRegex = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/g;
    const urlRegex = /(https?:\/\/[^\s]+)/g;
  
    const parts = description.split(/(\s+)/);
    return parts.map((part, index) => {
      if (part.match(emailRegex)) {
        return (
          <Link href={`mailto:${part}`} key={index}>
            {part}
          </Link>
        );
      }
      if (part.match(urlRegex)) {
        const isHttps = part.match(/^https:\/\//);
        return (
          <Link href={part} target={isHttps ? '_blank' : undefined} rel="noopener noreferrer" key={index}>
            {part}
          </Link>
        );
      }
      return part;
    });
  };
  
  

const Title = styled.h2`
  font-size: 2rem;
  margin: 2rem 0 0 0;
  text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.5);
`;

const Description = styled.p`
  color: gray;
  margin: 1rem 0 2rem 0;
`;

const Link = styled.a`
  color: #a72609;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
`;

interface Props {
  title: string;
  description: string;
}

export default ShadowHeadingLink;

