import styled from 'styled-components';

const ShadowHeading = ({ title, description }: Props) => (
  <>
    <Title>{title}</Title>
    {description && <Description>{description}</Description>}
  </>
);

const Title = styled.h2`
  font-size: 2rem;
  margin: 2rem 0 0 0;
  text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.5);
`;

const Description = styled.p`
  color: gray;
  margin: 1rem 0 2rem 0;
`;

interface Props {
  title: string;
  description?: string;
}

export default ShadowHeading;
