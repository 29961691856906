import styled from 'styled-components'

const Heading = ({ title, description }: Props) => (
  <>
    <Title>{title}</Title>
    {description && <Description>{description}</Description>}
  </>
)

const Title = styled.h2`
  font-size: 2rem;
  margin: 2rem 0 0 0;
`

const Description = styled.p`
  color: gray;
  margin: 1rem 0 2rem 0;
`

interface Props {
  title: string
  description?: string
}

export default Heading
