import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'

import { store } from 'app/store'
import App from './App'

import './index.css'

const container = document.getElementById('root')!
const root = createRoot(container)
//const url = 'https://ipfs.io/ipfs/QmQ2DvmDABfHNCYfgKWfK2n3EupcBB6wn2HgrEBzuQRbaT?filename=Ruin.epub'

root.render(
  <Provider store={store}>
    <App />
  </Provider>
)

//<Provider store={store}>

//</Provider>
