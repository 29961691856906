import books from 'data/books'
import styled from 'styled-components'
import BookCover from './BookCover'
import Heading from './Heading'

const BookList = ({ title, description, ignoreTokenId }: Props) => {
  const shuffledBooks = books
    .filter((b) => b.tokenId !== ignoreTokenId)
    .sort(() => 0.5 - Math.random())

  return (
    <>
      <Heading title={title} description={description} />
      <List>
        {shuffledBooks.slice(0, 6).map((book) => (
          <BookCover
            key={book.tokenId}
            imageUrl={`/images/covers/${book.tokenId}.png`}
            tokenId={book.tokenId}
          />
        ))}
      </List>
    </>
  )
}

const List = styled.div`
  display: flex;
  justify-content: space-between;
`

interface Props {
  title: string
  description: string
  ignoreTokenId?: number
}

export default BookList
